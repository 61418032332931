.footer-info {
  grid-area: availability;
  display: flex;
  flex-direction: column;
}

.footer-info-title {
  font-weight: bold;
}

.footer-info-element {
  margin-bottom: 10px;
}
