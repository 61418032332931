@import '../../common/common.scss';

.contact {
  grid-area: contact;
}

.contact-content {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: auto;
  grid-template-areas:
    'title title title'
    'address address address'
    // "phone phone phone"
    'reservation reservation reservation'
    'map map map';
  width: 100%;
  justify-items: center;
  align-items: center;
  column-gap: 10px;
  row-gap: 30px;
  text-align: center;

  @media (min-width: $screen-lg-min) {
    margin: 0 auto;
    max-width: 800px;
    grid-template-columns: repeat(2, auto);
    column-gap: 15px;
    row-gap: 40px;
    grid-template-areas:
      'title title'
      'address reservation'
      'map map';
    margin-bottom: 20px;
  }
}

.contact-title {
  grid-area: title;
}

.contact-address {
  grid-area: address;
}

.contact-subtitle {
  padding-bottom: 10px;
}

.contact-email {
  grid-area: email;
}

.contact-phone {
  grid-area: phone;
}

.contact-btn {
  font-size: 1em;
}

.contact-phone-btn {
  img {
    height: 24px;
    padding-right: 10px;
  }
}

.contact-email-btn {
  img {
    height: 24px;
    padding-right: 10px;
  }
}

.contact-reservation {
  grid-area: reservation;
}

.contact-reservation-buttons {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}

.contact-map {
  grid-area: map;
  height: 400px;
}
