@import '../../common/common.scss';

.card {
  background: white;
  overflow: hidden;
  width: 100%;

  @media (min-width: $screen-md-min) {
    border-radius: 10px;
  }
}
