@import '../../common/common.scss';

.menu {
  grid-area: menu;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.menu-title {
  margin: 10px 0;
}

.menu-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.menu-button {
  width: 45px;
  font-size: 1em !important;

  @media (min-width: $screen-md-min) {
    width: 60px;
  }
}

.menu-buttons-title {
  text-align: center;
}

.menu-buttons-item {
  display: flex;
  padding: 20px 10px;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  @media (min-width: $screen-md-min) {
    display: none;
  }
}

.menu-buttons-item-web {
  display: none;

  @media (min-width: $screen-md-min) {
    padding: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    display: flex;
  }
}

.menu-card {
  display: block;

  @media (min-width: $screen-md-min) {
    display: none;
  }

  img {
    width: 100%;
    padding: 10px;
  }
}

.menu-card-web {
  display: none;

  @media (min-width: $screen-md-min) {
    display: flex;
  }

  img {
    width: 100%;
    padding: 10px;
  }
}
