.footer-contact {
  grid-area: contact;
  display: flex;
  flex-direction: column;
}

.footer-contact-title {
  font-weight: bold;
}

.footer-contact-element {
  margin-bottom: 10px;
}
