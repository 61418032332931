@import "../../common/common.scss";

.information {
  grid-area: information;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 10px;
}

.information-container {
  display: inline-flex;
  flex-direction: column;
  color: black;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
}

.information-title {
  text-transform: uppercase;
  font-size: 1.5em;
}

.information-content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-size: 1.1em;

  div {
    padding: 5px;
  }
}

.information-reservation-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  a {
    margin-bottom: 5px;
  }
}
