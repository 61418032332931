@import '../../common/common.scss';

.main__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-template-areas:
    // 'announcement announcement'

    'information information'
    'informationImage informationImage'
    'availability availability'
    'availabilityImage availabilityImage'
    'menu menu'
    'targa-2020 targa-2020'
    'contact contact'
    'targa-2019 targa-2019';
  width: 100%;
  justify-items: center;
  align-items: center;
  column-gap: 50px;
  row-gap: 15px;
  margin: 10px 0;

  @media (min-width: $screen-lg-min) {
    grid-template-areas:
      // 'announcement announcement'

      'information information'
      'informationImage informationImage'
      'availability availability'
      'availabilityImage availabilityImage'
      'menu menu'
      'targa-2020 targa-2019'
      'contact contact';
    row-gap: 50px;
    margin: 30px 0;
  }

  @media (min-width: $screen-xl-min) {
    grid-template-areas:
      // 'announcement announcement'

      'information availability'
      'availabilityImage informationImage'
      'menu menu'
      'targa-2020 targa-2019'
      'contact contact';
    row-gap: 50px;
    margin: 30px 0;
  }
}
