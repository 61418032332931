@import '../../common/common.scss';

.footer {
  width: 100%;
  background-color: #e4e4e4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 15px;
  align-items: center;
}

.footer--container {
  max-width: 1400px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  width: 100%;
  justify-items: flex-start;
  align-items: flex-end;
  column-gap: 15px;
  row-gap: 30px;
  margin-bottom: 10px;
  padding: 10px 15px;
  grid-template-areas:
    'links links links'
    'availability availability availability'
    'contact contact contact'
    'external external copyright';

  @media (min-width: $screen-md-min) {
    grid-template-areas:
      'links contact availability'
      'external external copyright';
    row-gap: 15px;
  }
}

.footer--copyright {
  grid-area: copyright;
  justify-self: flex-end;
  font-size: 0.9em;

  @media (min-width: $screen-md-min) {
    font-size: 1.1em;
  }
}
