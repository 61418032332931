@import '../../common/common.scss';

.targa-2019 {
  grid-area: targa-2019;
  width: 90%;

  @media (min-width: $screen-md-min) {
    min-height: 350px;
    width: 100%;
  }

  .image-card__gradient {
    display: none;
  }

  img {
    object-fit: contain;
  }
}
