@import './common/common.scss';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'EB Garamond', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.3em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.button {
  height: 45px;
  border: none;
  border-radius: 10px;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  margin: 0 5px;
  padding: 0 10px;
  font-family: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: inherit;

  &--white {
    color: black;
    background-color: white;

    &:hover {
      box-shadow: 0px 15px 20px rgba(72, 73, 73, 0.4);
      filter: invert(1);
      transform: translateY(-2px);
    }
  }

  &--black {
    color: white;
    background-color: black;
  }

  picture {
    height: 32px;
  }
}

button:focus {
  outline: 0;
}

.link {
  color: black;
  transition: all 0.3s ease-in 0s;

  &:active,
  &:hover {
    text-decoration: underline;
  }
}

.font-smaller {
  font-size: 0.7em;
}

.font-small {
  font-size: 0.8em;
}

.font-big {
  font-size: 1.2em;
}

.font-bigger {
  font-size: 1.4em;
}

.title {
  text-transform: uppercase;
  font-size: 1.5em;
}
