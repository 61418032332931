@import '../../common/common.scss';

.about-us {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: auto;
  grid-template-areas:
    'title title title title title'
    'image-1 image-1 image-1 image-1 image-1'
    'content-1 content-1 content-1 content-1 content-1'
    'image-2 image-2 image-2 image-2 image-2'
    'content-2 content-2 content-2 content-2 content-2'
    'image-3 image-3 image-3 image-3 image-3'
    'content-3 content-3 content-3 content-3 content-3';
  margin: 30px 0;
  width: 100%;
  justify-items: center;
  align-items: center;
  column-gap: 15px;
  row-gap: 15px;

  @media (min-width: $screen-md-min) {
    grid-template-areas:
      'title title title title title'
      'image-1 image-1 content-1 content-1 content-1'
      'content-2 content-2 content-2 image-2 image-2'
      'image-3 image-3 content-3 content-3 content-3';
  }
}

.about-us--content {
  padding: 0 25px;
  text-align: justify;
}

.about-us--header {
  display: flex;
  width: 100%;
  letter-spacing: 2.5px;
  text-align: left;
  border-radius: 3px;
  font-weight: bolder;
  text-transform: uppercase;
}

.about-us-title {
  grid-area: title;
  justify-content: center;
  font-size: 1.1em;

  @media (min-width: $screen-md-min) {
    font-size: 2.2em;
  }
}

.about-us-image1 {
  grid-area: image-1;
}
.about-us-image2 {
  grid-area: image-2;
}
.about-us-image3 {
  grid-area: image-3;
}

.about-us-content1 {
  grid-area: content-1;
}
.about-us-content2 {
  grid-area: content-2;
}
.about-us-content3 {
  grid-area: content-3;
}
