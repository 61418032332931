@import '../../common/common.scss';

.image-card {
  box-shadow: 0px 12px 15px rgba(0, 0, 0, 0.05);
  display: block;
  position: relative;
  width: 100%;
  transition: all 0.3s ease 0s;
  height: 250px;

  &:hover,
  &:active,
  &:focus {
    box-shadow: 0px 15px 20px rgba(72, 73, 73, 0.4);
    transform: translateY(-5px);
  }

  @media (min-width: $screen-md-min) {
    height: 90%;
  }
}

.image-card--max-height {
  max-height: 250px;
}

.image-card__gradient {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  background: black;
  opacity: 0.2;
}

.image-card__image {
  height: 100%;
  width: 100%;
  object-fit: cover;

  @media (min-width: $screen-md-min) {
    height: 100%;
  }
}

.image-card__text-container {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
}
