.availability {
  grid-area: availability;
}

.availability-container {
  display: flex;
  flex-direction: column;
  color: black;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
}

.availability-title {
  font-size: 1.3em;
  text-transform: uppercase;
}

.availability-content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.availability-content--item {
  padding: 5px;
}
