.mobile-menu-overlay {
  padding: 0 15px;
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.97);
}

.mobile-menu-toggle {
  z-index: 120 !important;
  img {
    height: 32px;
    width: 32px;
  }
}

.mobile-menu-items {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
  width: 100%;

  .external {
    display: flex;
    flex-direction: row;

    .button {
      padding: 10px;
    }
  }

  .button {
    margin-bottom: 30px;
    font-size: 1em;
    letter-spacing: 2.5px;
    font-weight: 600;
    padding: 25px;
  }
}
