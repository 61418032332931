@import '../../common/common.scss';

.navigation-web {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  .button {
    letter-spacing: 1px;
    font-size: 0.8em;

    @media (min-width: $screen-xl-min) {
      font-size: 1em;
    }
  }
}

.external {
  display: flex;
  flex-direction: row;
}
