@import '../../common/common.scss';

.header {
  width: 100%;
  position: sticky;
  top: 0;
  background-color: white;
  display: flex;
  justify-content: center;
  z-index: 100;

  .header-wrapper {
    max-width: 1920px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
  }

  .header-wrapper--left {
    justify-content: flex-start;

    @media (max-width: $screen-md-min) {
      flex: 1;
    }
  }

  .header-wrapper--right {
    flex: 0;

    @media (min-width: $screen-md-min) {
      flex: 1;
    }
  }

  .header-logo {
    font-size: 1.5em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    z-index: 120;

    picture {
      padding: 5px;
      z-index: inherit;
      source,
      img {
        z-index: inherit;
      }
    }

    button {
      z-index: inherit;
    }
  }

  .header-menu--web {
    display: none;

    div {
      padding: 3px;
    }

    @media (min-width: $screen-md-min) {
      display: block;
    }
  }

  .header-menu--mobile {
    display: flex;

    @media (min-width: $screen-md-min) {
      display: none;
    }
  }
}
