@import '../../common/common.scss';

.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .wrapper--inner {
    width: 100%;
    max-width: 1500px;

    @media (min-width: $screen-md-min) {
      padding: 10px;
    }
  }
}
