@import '../../common/common.scss';

.hero {
  width: 100%;
  height: 80vh;
}

.hero-background-img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.hero-language-switcher {
  position: relative;
  bottom: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-teaser--wrapper {
  position: absolute;
  height: inherit;
  width: 100%;
  left: 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-teaser {
  font-weight: bolder;
  background-color: white;
  padding: 10px 30px;
  text-align: center;
  color: black;
  font-size: 1.6em;
  border-radius: 15px;
  box-shadow: 7px 7px 18px 0px rgba(0, 0, 0, 0.8);

  @media (min-width: $screen-lg-min) {
    font-size: 2.4em;
  }

  @media (min-width: $screen-md-min) {
    font-size: 2.7em;
  }

  @media (min-width: $screen-xl-min) {
    font-size: 3.6em;
  }
}

.hero-teaser--title {
  transform: rotate(2deg);
}

.hero-teaser--subtitle {
  transform: rotate(-3deg);
}

.hero-title-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-subtitle-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-radio-gong {
  position: absolute;
  top: 100px;
  right: 20px;
  z-index: 50;
  height: 100px;

  @media (min-width: $screen-md-min) {
    height: 150px;
    top: 120px;
    right: 40px;
  }

  img {
    height: 100%;
  }
}
