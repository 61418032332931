.footer-links-external {
  grid-area: external;
  display: flex;
  flex-direction: row;
  justify-self: flex-start;

  a {
    margin-right: 20px;
  }
}
