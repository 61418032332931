@import '../../common/common.scss';

.text-card-container {
  display: flex;
  flex-direction: column;
  color: black;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  align-items: center;
  text-align: center;
}

.text-card-content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-size: 1.1em;
}

.text-card-content--item {
  padding: 5px;
}
